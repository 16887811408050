<script>
    import Form from '../Form.vue';
    import ModalManager from '../../services/modalManager';
    import axios from 'axios';
    import DialogManager from '../../services/DialogManager';
    import jsonApi from '../../json-api-client';
    import apiUrl from '../../services/apiUrl';
    import HandlesErrorResponses from '../../mixins/HandlesErrorResponses';

    export default {
        extends: Form,

        mixins: [HandlesErrorResponses],

        props: {
            url: {
                type: String,
                required: true,
            },

            twinfieldSuccessUrl: {
                type: String,
                required: true,
            },

            twinfieldErrorUrl: {
                type: String,
                required: true,
            },

            twinfieldConnectUrl: {
                type: String,
                required: true,
            },

            twinfieldDataUrl: {
                type: String,
                required: true,
            },

            twinfieldDestroyUrl: {
                type: String,
                required: true,
            },
        },

        data() {
            return {
                loading: false,
                syncing: false,
                connectAfterFetch: false,
            };
        },

        mounted() {
            ModalManager.$on('modalClosed', ({ parent_id: parentId }) => {
                if (!this.$refs.zorgMailOverview) {
                    return;
                }

                // Change the overview and table url to make sure the data fetched is of the correct external interface
                const url = this.$refs.zorgMailOverview.config.url;

                this.$refs.zorgMailOverview.setUrl(url.replace('-1', parentId));
            });
        },

        computed: {
            hasTwinfieldAdministration() {
                return !!this.formData.twinfield_administration_id;
            },

            twinfieldAdministrationId: {
                get() {
                    if (!this.hasTwinfieldAdministration) {
                        return null;
                    }

                    return String(this.formData.twinfield_administration_id);
                },
                set(value) {
                    this.formData.twinfield_administration_id = value;
                },
            },

            twinfieldCanConnect() {
                return this.hasTwinfieldAdministration && !this.formData.twinfield_administration.exists_in_connector;
            },

            twinfieldCanConsent() {
                return this.hasTwinfieldAdministration &&
                    this.formData.twinfield_administration.exists_in_connector &&
                    !this.formData.twinfield_administration.is_authenticated &&
                    this.formData.twinfield_administration.consent_uri;
            },

            twinfieldIsAuthenticated() {
                return this.hasTwinfieldAdministration && this.formData.twinfield_administration.is_authenticated;
            },

            submitButtonEnabled() {
                return !this.$store.state.core.requestPoolPending && !this.formSubmitted && !this.syncing;
            },
        },

        methods: {
            navigateToInstitute(instituteId) {
                window.location.assign(this.url.replace(':id', instituteId));
            },

            async syncTwinfield() {
                this.syncing = true;

                try {
                    await jsonApi.axios.post(apiUrl('/twinfield/sync_exportrelations'));
                } catch (err) {
                    this.handleErrorResponse(err).then();
                }

                this.syncing = false;
            },

            async fetchTwinfieldAdministration(id) {
                this.loading = true;

                const url = this.twinfieldDataUrl.replace('_twinfield_administration_id_', id);
                const data = (await axios.get(url)).data.data;

                this.$set(this.formData, 'twinfield_administration', data);
                this.loading = false;

                if (this.connectAfterFetch) {
                    this.connectAfterFetch = false;

                    return this.connectTwinfieldAdministration();
                }
            },

            async connectTwinfieldAdministration() {
                this.loading = true;

                const data = (await axios.post(this.twinfieldConnectUrl.replace('_twinfield_administration_id_', this.twinfieldAdministrationId), {
                    success_uri: this.twinfieldSuccessUrl.replace('_twinfield_administration_id_', this.twinfieldAdministrationId),
                    error_uri: this.twinfieldErrorUrl.replace('_twinfield_administration_id_', this.twinfieldAdministrationId),
                    institute_id: this.formData.id,
                })).data.data;

                this.$set(this.formData, 'twinfield_administration', data);

                if (this.twinfieldCanConsent) {
                    return this.consentTwinfieldAdministration();
                }

                this.loading = false;
            },

            async consentTwinfieldAdministration() {
                this.formSubmitted = true;
                window.location.href = this.formData.twinfield_administration.consent_uri;
            },

            async deleteTwinfieldAdministration() {
                await DialogManager.confirm(this.$t('settings.external-interfaces.twinfield-api.messages.delete_confirm'));

                this.loading = true;

                await axios.post(this.twinfieldDestroyUrl.replace('_twinfield_administration_id_', this.twinfieldAdministrationId));

                this.loading = false;
                this.twinfieldAdministrationId = null;
            },

            handleNewAdministration(id) {
                this.connectAfterFetch = true;
                this.twinfieldAdministrationId = id;
            },
        },

        watch: {
            twinfieldAdministrationId(newVal, oldVal) {
                if (!newVal || String(newVal) === String(oldVal)) {
                    return;
                }

                this.fetchTwinfieldAdministration(newVal);
            },
        },
    };
</script>
