export default {
    name: 'calendar_employee_schedule',
    definition: {
        color_location: '',
        employee_id: '',
        end: '',
        interval: '',
        location_id: '',
        device_id: '',
        room_id: '',
        start: '',
    },
    options: {
        collectionPath: 'calendar/employee_schedules',
    },
};
