<script>
    import Form from '../Form.vue';

    export default {
        extends: Form,

        computed: {
            /**
             * @see https://github.com/vuejs/vue/issues/844#issuecomment-265315349
             */
            nameWatchable() {
                // noinspection CommaExpressionJS
                return this.formData.gender, this.formData.name.lastname, this.formData.name.lastname_prefix, this.formData.name.initials, this.formData.name.firstname, Date.now(); // eslint-disable-line no-sequences
            },

            specificationAndCategoryRequired() {
                const agb = this.formData.agb;

                if (!agb) {
                    return false;
                }

                // check if AGB matches the pattern 2 digits, 6 zeros
                return /^\d{2}0{6}$/.test(agb);
            },
        },

        watch: {
            nameWatchable() {
                this.fillSalutationAndAddressingFields();
            },
        },

        methods: {
            fillSalutationAndAddressingFields() {
                if (this.formData.gender && this.formData.name.lastname && this.formData.name.initials && this.formData.name.firstname) {
                    if (!this.formData.name.salutation) {
                        this.$set(this.formData.name, 'salutation', this.getSalutation());
                    }

                    if (!this.formData.name.addressing) {
                        this.$set(this.formData.name, 'addressing', this.getAddressing());
                    }
                }
            },

            /**
             * @returns {string}
             */
            getSalutation() {
                return this.$t('masterfiles.values.salutation-' + this.formData.gender.toLowerCase(), { name: this.getName(false) });
            },

            /**
             * @returns {string}
             */
            getAddressing() {
                return this.$t('masterfiles.values.addressing-' + this.formData.gender.toLowerCase(), { name: this.getName(true) });
            },

            /**
             * @param {boolean} includeInitialsOrFirstname
             *
             * @returns {string}
             */
            getName(includeInitialsOrFirstname) {
                let name = '';

                if (includeInitialsOrFirstname) {
                    name += (this.formData.name.initials ? this.formData.name.initials : this.formData.name.firstname) + ' ';
                }

                if (this.formData.name.lastname_prefix) {
                    name += this.formData.name.lastname_prefix + ' ';
                }

                name += this.formData.name.lastname;

                return name;
            },
        },
    };
</script>
