import jsonApi from '../json-api-client';
import apiUrl from './apiUrl';

const fetchJob = async(jobId) => (await jsonApi.axios.get(apiUrl(`/job/${jobId}`))).data;
const jobIsCompleted = (job) => job.attributes.status === 'Completed';
const jobIsFailed = (job) => job.attributes.status === 'Failed';

const calculateTimeout = (retryCount) => {
    if (retryCount < 8) {
        return 250;
    } else if (retryCount < 12) {
        return 500;
    } else if (retryCount < 16) {
        return 1000;
    } else {
        return 2000;
    }
};

/**
 *
 * @param {{id: string, on_complete: string}} job
 * @returns {Promise<void>}
 */
const awaitJob = (job) => new Promise((resolve, reject) => {
    const poll = async(retryCount = 0) => {
        const jobResponse = await fetchJob(job.id);

        if (jobIsCompleted(jobResponse.data)) {
            window.location.href = String(job.on_complete);
            resolve();
        } else if (jobIsFailed(jobResponse.data)) {
            reject(new Error(
                    jobResponse.included?.find((included) => included.type === 'error')?.attributes?.detail));
        } else {
            setTimeout(() => poll(retryCount + 1), calculateTimeout(retryCount));
        }
    };

    poll().then();
});

export default awaitJob;
