<template>
    <svg :class="className" xmlns="http://www.w3.org/2000/svg">
        <title v-if="title">{{ title }}</title>
        <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" />
    </svg>
</template>

<script>
    export default {
        props: {
            name: {
                type: String,
                required: true,
            },

            title: {
                type: String,
                default: null,
            },

            rotating: {
                type: Boolean,
                default: false,
            },
        },

        computed: {
            iconPath() {
                return require(`../../icons/${this.name}.svg`).default.url;
            },

            className() {
                const classes = ['frm-icon', `frm-icon--${this.name}`];

                if (this.rotating) {
                    classes.push('frm-icon--rotating');
                }

                return classes.join(' ');
            },
        },
    };
</script>
